@font-face {
  font-family: 'KosugiMaru';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/family/KosugiMaru-Regular.ttf') format('truetype');
}
.list-mainvisual {
  font-family: "KosugiMaru", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  height: calc(100vh - 128px);
  margin-top: 64px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-bottom: 1px solid #ddd;
}
.list-mainvisual__pic {
  width: calc(100% - 280px - 32px);
  height: calc(100% - 64px);
  border-radius: 24px 0 0 24px;
  overflow: hidden;
  position: relative;
}
.list-mainvisual__pic ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
.list-mainvisual__pic li {
  width: 100%;
  flex-shrink: 0;
  transition: all 0.4s ease;
}
.list-mainvisual__pic li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-mainvisual__circle {
  width: 560px;
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: calc(50% - 280px);
  left: 32px;
}
.list-mainvisual__circle__bg {
  width: 98%;
  height: 98%;
  border-radius: 100%;
  background: var(--theme-background-color);
  position: absolute;
  top: 1%;
  left: 1%;
}
.list-mainvisual__circle__border {
  width: 100%;
  height: 100%;
  fill: var(--theme-primary-color);
  position: absolute;
  top: 0;
  left: 0;
}
.list-mainvisual__circle__txt {
  max-width: 400px;
  text-align: center;
  position: relative;
}
.list-mainvisual__circle__txt__ttl {
  font-size: 44px;
  line-height: 1.2;
  white-space: pre-line;
  font-weight: bold;
}
.list-mainvisual__circle__txt__desc {
  margin-top: 24px;
  white-space: pre-line;
}
.list-mainvisual__btns {
  width: 96px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 16px;
  bottom: 80px;
}
.list-mainvisual__btns__btn {
  width: 32px;
  height: 32px;
  background: rgba(0,0,0,0.6);
  border-radius: 100%;
  position: relative;
}
.list-mainvisual__btns__btn::after {
  content: "";
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: 11px;
  left: 13px;
  transform: rotate(45deg);
}
.list-mainvisual__btns__btn.next {
  transform: scaleX(-1);
}
.list-mainvisual__btns__border {
  width: 1px;
  height: 100%;
  background: rgba(0,0,0,0.6);
}

@media screen and (max-width:768px) {
  .list-mainvisual {
    height: auto;
    margin-top: 0;
    display: block;
  }
  .list-mainvisual__pic {
    width: 100%;
    height: 360px;
    border-radius: 0;
  }
  .list-mainvisual__btns {
    bottom: 0;
    top: 16px;
  }
  .list-mainvisual__circle {
    width: 343px;
    height: 343px;
    margin: -64px auto 0 auto;
    position: relative;
    top: 0;
    left: 0;
  }
  .list-mainvisual__circle__txt {
    padding: 0 56px;
  }
  .list-mainvisual__circle__txt__ttl {
    font-size: 28px;
  }
  .list-mainvisual__circle__txt__desc {
    font-size: 14px;
    margin-top: 8px;
  }
}